/*modal*/

.modal{
  display: none;
  max-width: 100%;
  background-color: #fff;
  overflow: visible;
}

.modal, .modal > * {
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s;
}

.fancybox-slide--current .modal, .fancybox-slide--current .modal > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}