/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.footer {
  padding-bottom: 287px;

  & .container {

  }
  & .line {
    margin: 0 auto;
    width: 2060px;
    &:before {

    }
    &_2 {
      &:before {
        top: 210px;
      }
    }
    &_1 {
      &:after {
        position: absolute;
        content: '';
        display: block!important;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
        transform: rotate(46.79deg);
        width: 39px;
        height: 59px;
        right: -8px;
        bottom: 16px;
        top: auto;
      }
    }
  }
  &__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(25% - 60px);
    margin-right: 60px;
    &.hidden {
      display: none;
    }
    &:first-child {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    &:last-child {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
  }

  &__logo {
    width: 190px;
    height: 36px;
    margin-bottom: 16px;
    text-align: left;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__wp {
    margin-right: -60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .wrap-nav {

  }

  & .menu {
    &-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      & a {
        &:hover {
          color: #0153FF;
        }
      }
    }
  }

  &__address {
    max-width: 181px;

    & p {
      &:first-child {
        font-weight: 600;
        margin-bottom: 16px;
      }
    }
  }

  &__btn {
    & a {
      background: rgba(1, 83, 255, 0.16);
      border-radius: 10px;
      width: 197px;
      padding: 8px 8px 8px 12px;
      font-weight: 600;
      color: #0A3FC7;

      & svg {
        fill: #0A3FC7;
      }

      &:hover {
        color: #DA49D9;

        & svg {
          fill: #DA49D9;
        }
      }

      & span {
        vertical-align: middle;
        margin-right: 10px;
        width: 15px;
        margin-top: -8px;
        height: 16px;
      }
    }
  }

  &__note {
    color: #5A5B61;
    margin-bottom: 69px;
    max-width: 260px;
  }

  &__year {

  }

  & .line {
    &:after {
      display: none;
    }

    &_1 {
      bottom: -12px;
      width: 2057px;
      @media (max-width: 1998px) {
        left: -50px;
      }
    }

    &_2 {
      width: 1723px;
      bottom: 20px;
      @media (max-width: 1998px) {
        left: -50px;
      }
    }
  }
}

@media (max-width: 1239px) {

}

@media (max-width: 1239px) {
  .footer__logo {
    margin-right: 100px;
  }
  .footer {
    padding-bottom: 150px;
  }
}

@media (max-width: 1139px) {
  .footer {
    padding-bottom: 100px;
  }
  .footer__block {
    width: calc(25% - 25px);
    margin-right: 25px;
  }
  .footer__wp {
    margin-right: -25px;
  }
}

@media (max-width: 1023px) {
  .footer__logo {
    margin: 0 auto;
  }
  .footer__wp {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
  }
  .footer__year {
    display: none;
  }
  .footer .menu-item {
    margin-bottom: 5px;
  }
  .footer__logo {
    margin-bottom: 10px;
  }
  .footer__note {
    margin: 0 auto;
  }
  .footer__block {
    width: calc(50% - 15px);
    margin-right: 15px;
    margin-bottom: 20px;
    &:nth-child(4) {
      width: 100%;
    }
    &:first-child {
      width: 100%;
      text-align: center;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.hidden {
      display: block;
      text-align: center;
      width: 100%;
      .footer__year {
        display: block;
      }
    }
  }



  .footer {
    padding-bottom: 30px;
  }

  .footer__logo {
    width: 160px;
    height: 30px;
  }

  .footer .menu-item {
    margin-bottom: 10px;
  }

  .footer__address p:first-child {
    margin-bottom: 10px;
  }
}