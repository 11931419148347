/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.section {
  margin-bottom: 200px;
}

.circle {
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-animation: scale infinite 5s;
  animation: scale infinite 5s;
}

.line {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: -1;
  &:after, &:before {
    top: -30px;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    width: 60px;
    z-index: 2;
    background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), color-stop(0.01%, #FFFFFF), to(rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(right, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
  }
  &:after {
    right: 0;
  }
  &:before {
    left: 0;
    background: -o-linear-gradient(2deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(88deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
  }
}

.scale {

}

//animate cirles
@keyframes scale  {
  0% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@media (max-width: 1139px) {
  .section {
    margin-bottom: 100px;
  }
}
@media (max-width: 1023px) {
  .section {
    margin-bottom: 60px;
  }
  .circle {
    display: none;
  }
}