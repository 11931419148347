/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

//normalize

* {
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

a, button, span {
  display: inline-block
}

input, textarea {
  display: block;
  width: 100%
}

table {
  width: 100%;
  border-collapse: collapse
}

a {
  background-color: transparent;
  text-decoration: none
}

img {
  border: none;
  max-width: 100%;
  height: auto
}

a, button {
  outline: 0;
  cursor: pointer
}

li {
  list-style: none
}

button {
  background: 0 0;
  border: none
}

a, button, input, textarea {
  color: $black;
  font-weight: inherit;
  -webkit-appearance: none;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

:after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0
}

input {
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0
}

input[type=number] {
  -moz-appearance: textfield
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block
}

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none
}

.text-align-left {
  text-align: left
}

.text-align-center {
  text-align: center
}

.text-align-right {
  text-align: right
}

.full-img img {
  width: 100%
}

figure {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: .75em;
  opacity: .8
}

[hidden] {
  display: none !important
}

.img-full {
  width: 100%;
}

.clearfix {
  &:after {
    content: '';
    clear: both;
    display: block
  }
}

//title

* div, span, a, :after, :before {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

h1, .h1 {
  margin: 0;
}

h2, .h2 {
  margin: 0;
  font-weight: 600;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -0.04em;
  color: #1A1A1D;
  max-width: 990px;
  & span {
    color: #0153FF;
  }
  @media (max-width: 1439px) {
    font-size: 70px;
    line-height: 70px;
  }
  @media (max-width: 1239px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 1139px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 1023px) {
    font-size: 30px;
    line-height: 30px;
  }
}

h3, .h3 {
  margin: 0;
}

h4, .h4 {
  margin: 0;
}

h5, .h5 {
  margin: 0;
}

h6, .h6 {
  margin: 0;
}

//text

.text {

  & > * + * {

  }
}

//body

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #1A1A1D;
  background: #FFFEFC;
  @media (max-width: 735px) {
    font-size: 13px;
    line-height: 24px;
  }
}

.wrapper {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
}

main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: block;
}

footer {
  margin: auto auto 0 auto;
  width: 100%;
}

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 2;
  @media (max-width: 1239px) {
    padding: 0 40px;
  }
  @media (max-width: 1023px) {
    padding: 0 20px;
  }
}

.container {
  max-width: 1680px;
}

.container-fluid {
  max-width: 1920px;
}


//btn

.btn {

}

.btn-row {
  text-align: center;

  .btn {
    margin: 10px;
  }
}


//row

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -20px;

  .column-2 {
    width: 50%;
    padding: 10px 20px;
  }

  .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px;
  }
}

//fancybox

.fancybox-navigation {
  position: static;
}

//swiper

.swiper-button {
  outline: none;

  &.swiper-button-prev {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  &:before, &:after {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  .swiper-pagination-bullet {
    opacity: 1;
    outline: none;

    &.swiper-pagination-bullet-active {

    }
  }
}

