/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.homescreen {
  padding: 164px 0 82px 0;
  margin-bottom: 232px;

  & .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__text {
    width: 53%;
    margin-right: 84px;
    margin-top: -140px;
  }

  &__img {
    width: calc(47% - 84px);

    & img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    &_text {
      background: #FFFEFC;
      -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
      box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
      border-radius: 12px;
      position: absolute;
      right: 53px;
      bottom: 73px;
      padding: 10px 20px;
      font-weight: 600;
      line-height: 28px;
      color: #0153FF;

    }
  }

  &__title {
    font-weight: 600;
    font-size: 90px;
    line-height: 90px;
    letter-spacing: -0.04em;
    color: #1A1A1D;
    margin-bottom: 20px;
  }

  &__note {
    font-weight: bold;
    font-size: 34px;
    line-height: 37px;
    color: #0153FF;
    max-width: 760px;
  }

  & .line {
    &_1 {
      left: 142px;
      top: 365px;
      width: 1834px;
      margin: 0 auto;
      &:before {
        display: none;
      }

      & .circle_1 {
        bottom: 245px;
        left: -17px;
        background: #DA49D9;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 2;
      }
    }

    &_2 {
      width: 1996px;
      top: 55%;
      margin: 0 auto;
      @media (max-width: 1998px) {
        left: -40px;
      }
    }
  }

  & .circle {
    &_1 {
      background: #0153FF;
      width: 30px;
      height: 30px;
      top: -16px;
      left: 46%;
    }

    &_2 {
      background: #DA49D9;
      width: 20px;
      height: 20px;
      right: -130px;
      top: 31px;
    }

    &_3 {
      background: #0153FF;
      width: 80px;
      height: 80px;
      right: -160px;
      bottom: -93px;
    }
  }
}

.about {
  padding: 0 0 600px 0;

  & p {
    z-index: 2;
    text-align: center;
    font-size: 24px;
    line-height: 48px;
  }

  & .line {
    &_1 {
      width: 2000px;
      bottom: -215px;
      z-index: 2;
      margin: 0 auto;
      @media (max-width: 1998px) {
        left: -60px;
      }
    }

    &_2 {
      bottom: -213px;
      width: 2000px;
      margin: 0 auto;
      @media (max-width: 1998px) {
        left: -30px;
      }
    }
  }

  & .circle {
    &_1 {
      background: #DA49D9;
      width: 80px;
      height: 80px;
      left: -100px;
      top: 100px;
    }

    &_2 {
      background: #DA49D9;
      width: 20px;
      height: 20px;
      bottom: -200px;
      left: 200px;
    }

    &_3 {
      background: #0153FF;
      width: 30px;
      height: 30px;
      right: -100px;
      bottom: -500px;
    }
  }
}

.numbers {
  &__wp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-right: -30px;
    margin-bottom: 60px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  &__item {
    width: calc(16.6% - 30px);
    margin-right: 30px;
  }

  &__block {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    color: #0153FF;
    text-transform: uppercase;

    &_plus {
      color: #DA49D9;
    }
  }

  &__info {
    text-align: left;
    max-width: 174px;
  }

  &__note {
    text-align: center;
    color: #8F9099;

    & span {
      color: #0153FF;
    }
  }
}


.video {
  text-align: center;

  &__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  & p {
    max-width: 800px;
    margin: 0 auto 40px auto;
  }

  &__wp {
    z-index: 6;
    text-align: left;
    padding: 33px 0;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    height: 450px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #6798FF;
      -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.1), 0px 8px 12px rgba(111, 144, 209, 0.2);
      box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.1), 0px 8px 12px rgba(111, 144, 209, 0.2);
      border-radius: 10px;
    }

    & img {
      z-index: 2;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__bg {
    position: absolute;
    left: 16%;
    top: 63px;
    z-index: 4;
  }

  &__play {
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #1A1A1D;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 10px 10px 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;

    & img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    &:hover {
      background: #FF3F5D;
    }
  }
}


.arrowAnim {
  width: 300px;
  height: 67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -2;

  &_2 {
    right: -300px;
  }

  &_1 {
    left: -300px;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
}

.arrow {
  width: 67px;
  height: 218px;
  border-color: #CDEDFF transparent transparent #CDEDFF;
}


.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}


@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10vw);
    transform: translateX(10vw);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(7vw);
    transform: translateX(7vw);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(-7vw);
    transform: translateX(-7vw);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-10vw);
    transform: translateX(-10vw);
  }
}


@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10vw);
    transform: translateX(10vw);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(7vw);
    transform: translateX(7vw);
  }
  80% {
    opacity: 1;
    -webkit-transform: translateX(-7vw);
    transform: translateX(-7vw);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-10vw);
    transform: translateX(-10vw);
  }
}

.steps {
  margin-bottom: 227px;

  &__line {
    position: absolute;
    left: -155px;
    top: 91px;
    z-index: -1;
  }

  &__item {
    padding-bottom: 50px;

    &:first-child {
      padding-left: 70px;

      & .steps__icon {
      }
    }

    &:nth-child(2) {
      padding-left: 400px;
      margin-top: -50px;

      & .steps__icon {
        bottom: -25px;
      }
    }

    &:nth-child(3) {
      padding-left: 660px;
      margin-top: 25px;

      & .steps__icon {
        bottom: 2px;
        left: -65px;
      }
    }

    &:nth-child(4) {
      padding-left: 760px;
      margin-top: 180px;
      margin-bottom: 90px;

      & .steps__icon {
        bottom: 15px;
        left: -98px;
        -webkit-box-shadow: 0px 10px 36px rgba(218, 73, 217, 0.5);
        box-shadow: 0px 10px 36px rgba(218, 73, 217, 0.5);
        width: 70px;
        height: 70px;
      }
    }

    &:nth-child(5) {
      margin: 0 auto 25px auto;
      padding-left: 635px;

      .steps__icon {
        bottom: -4px;
        left: -68px;
      }
    }

    &:nth-child(6) {
      margin: 0 auto 10px auto;
      padding-left: 720px;
      padding-bottom: 10px;

      & .steps__text {
        max-width: 230px;
      }

      & .steps__icon {
        left: -50px;
      }
    }

    &:nth-child(7) {
      margin: 0 0 30px auto;
      padding-left: 994px;

      .steps__icon {
        bottom: -65px;
        left: -10px;
      }
    }

    &:nth-child(8) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding-right: 0;
      margin-top: -140px;
      margin-right: -50px;

      .steps__icon {
        bottom: -65px;
        left: -10px;
      }
    }

    &:nth-last-child(-n + 4) {
      & .steps__icon {
        background: -o-radial-gradient(50% 50%, 50% 50%, #BBE7FF 0%, rgba(237, 249, 255, 0) 100%);
        background: radial-gradient(50% 50% at 50% 50%, #BBE7FF 0%, rgba(237, 249, 255, 0) 100%);

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          background: #CDEDFF;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #FFFFFF;
          z-index: 2;
        }
      }
    }
  }

  &__text {
    & p {
      max-width: 300px;
    }
  }

  &__icon {
    position: absolute;
    bottom: -45px;
    left: -70px;
    background: #DA49D9;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  & .circle {
    &_1 {
      background: #0153FF;
      width: 20px;
      height: 20px;
      left: 25%;
      top: 41%;
    }

    &_2 {
      background: #0153FF;
      width: 50px;
      height: 50px;
      top: 29%;
      right: 27%;
    }

    &_3 {
      background: #DA49D9;
      width: 20px;
      height: 20px;
      right: 30px;
      top: 107px;
    }

    &_4 {
      background: #0153FF;
      width: 20px;
      height: 20px;
      right: 219px;
      top: 56%;
    }

    &_5 {
      background: #DA49D9;
      width: 20px;
      height: 20px;
      left: 74px;
      bottom: 26%;
    }

    &_6 {
      background: #0153FF;
      width: 30px;
      height: 30px;
      bottom: 140px;
      left: 25%;
    }
  }
}

.title {
  & .circle {
    &_1 {
      background: #0153FF;
      width: 50px;
      height: 50px;
      top: -55px;
      left: 350px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 34px;
    line-height: 48px;
    text-align: center;
    color: #1A1A1D;
    max-width: 800px;
    margin: 0 auto;
  }
}

.help {
  & h2 {
    margin-bottom: 120px;
  }

  &__wp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -40px;
    margin-bottom: -40px;
  }

  &__item {
    width: calc(25% - 40px);
    margin-right: 40px;
    margin-bottom: 40px;
    border: 1px solid #CDEDFF;
    border-radius: 40px;
    padding: 40px 40px 48px 40px;
  }

  &__icon {
    background: #0153FF;
    border-radius: 30px;
    margin-bottom: 40px;
    width: 118px;
    height: 118px;
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.block {
  &__heading {
    margin-bottom: 120px;

    & h2 {
      margin-bottom: 40px;
    }

    & p {
      letter-spacing: -0.04em;
      max-width: 500px;
    }
  }

  &__wp {
    margin-bottom: 107px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__img {
    width: 36%;
    margin-right: 11%;

    & img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  &__text {
    width: calc(64% - 11%);

    & ul {


      & li {
        padding-left: 34px;
        min-height: 266px;
        padding-bottom: 40px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 2px;
          top: 10px;
          bottom: -15px;
          width: 2px;
          background: #CDEDFF;
        }
        &:last-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 34px;
    color: #5A5B61;
    margin-bottom: 15px;

    &:before, &:after {
      display: block;
      position: absolute;
      content: '';
      border-radius: 50%;
    }

    &:before {
      left: -45px;
      top: -5px;
      width: 28px;
      height: 28px;
      background: #CDEDFF;
    }

    &:after {
      left: -38px;
      top: 2px;
      background: #FFFEFC;
      -webkit-box-shadow: 0px 2px 2px rgba(11, 43, 61, 0.25);
      box-shadow: 0px 2px 2px rgba(11, 43, 61, 0.25);
      width: 14px;
      height: 14px;
    }
  }

  & .line {
    z-index: -1;
    left: -230px;
    &:after, &:before {
      display: none;
    }

    &_1 {
      width: 1371px;
      top: 90px;
      right: auto;
      @media (min-width: 1999px) {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 60px;
          top: 0;
          bottom: 0;
          background: linear-gradient(88deg,rgba(255,255,255,0) 0,#fff .01%,rgba(255,255,255,0) 100%);
        }
      }
    }

    &_2 {
      right: auto;
      width: 940px;
      top: 82px;
      @media (min-width: 1999px) {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 60px;
          top: 0;
          bottom: 0;
          background: linear-gradient(88deg,rgba(255,255,255,0) 0,#fff .01%,rgba(255,255,255,0) 100%);
        }
      }
    }
  }

  & .circle {
    &_1 {
      background: #0153FF;
      width: 90px;
      height: 90px;
      left: -143px;
      bottom: 114px;
    }

    &_2 {
      background: #DA49D9;
      width: 62px;
      height: 62px;
      right: -223px;
      top: 16%;
    }
  }

  &__more {
    text-align: center;

    & a {
      font-family: 'Proxima Nova Lt';
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFEFC;
      background: #0153FF;
      border-radius: 20px;
      padding: 32px 56px;

      & span {
        margin-left: 16px;
        margin-bottom: -1px;
        vertical-align: middle;
      }

      &:hover {
        & span {
          -webkit-transform: translateX(15px);
          -ms-transform: translateX(15px);
          transform: translateX(15px);
        }
      }
    }
  }

  &:nth-child(2) {
    & .line {
      left: auto;
      right: -230px;
      &_1 {
        &:before {
          right: -23px;
          top: 27px;
          left: auto;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
          transform: rotate(39.13deg);
          width: 60px;
          height: 39px;
          bottom: auto;
        }
      }
      &_2 {
        &:before {
          left: auto;
          right: -16px;
          height: 60px;
          bottom: auto;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
          transform: rotate(50.57deg);
          width: 60px;
        }
      }
    }
    & .block__img {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      margin-right: 0;
      margin-left: 11%;
    }

    & .block__text {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    .gallery__slider {
      margin-bottom: 78px;
    }

    & .line {
      &_1 {
        width: 1950px;
        top: 375px;
      }

      &_2 {
        top: 313px;
        width: 1625px;
      }
    }

    & .circle {
      &_1 {
        bottom: auto;
        top: -188px;
        left: 35%;
        width: 30px;
        height: 30px;
      }

      &_2 {
        width: 90px;
        height: 90px;
        right: -173px;
        top: 35%;
      }
    }
  }

  &:nth-child(3) {
    .line {
      &_1 {
        width: 2000px;
        top: 488px;
      }

      &_2 {
        width: 2000px;
        top: 508px;
        z-index: -2;
        &:after {
          display: block!important;
          top: auto;
          bottom: 100px;
          right: -12px;
          background: linear-gradient(180deg,rgba(255,255,255,0) 0,#fff .01%,rgba(255,255,255,0) 100%);
          transform: rotate(52.21deg);
          width: 39px;
          height: 66px;
        }
      }
    }

    .circle {
      &_1 {
        background: #0153FF;
        width: 56px;
        height: 56px;
        left: -180px;
        top: 0;
      }

      &_2 {
        background: #0153FF;
        width: 30px;
        height: 30px;
        right: auto;
        left: -104px;
        top: 394px;
      }

      &_3 {
        background: #0153FF;
        width: 30px;
        height: 30px;
        right: -115px;
        top: 400px;
      }
    }

    & .block__wp {
      margin-bottom: 53px;
    }

    & .gallery__slider {
      margin-bottom: 74px;
    }
  }
}

.gallery {
  &__slider {
    padding-bottom: 50px;
    margin-bottom: 120px;
  }

  & .swiper-pagination.block__pagination {
    bottom: 0;
    margin-top: 0;

    & .swiper-pagination-bullet {
      background: #CDEDFF;

      &.swiper-pagination-bullet-active {
        background: #DA49D9;
      }
    }
  }

  &__item {

  }

  &__img {
    margin-bottom: 26px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(111, 144, 209, 0.1)) drop-shadow(0px 8px 12px rgba(111, 144, 209, 0.2));
    filter: drop-shadow(0px 4px 4px rgba(111, 144, 209, 0.1)) drop-shadow(0px 8px 12px rgba(111, 144, 209, 0.2));

    & img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  &__text {
    text-align: center;

  }
}

.form {
  margin-bottom: 260px;

  &__block {
    padding: 46px 147px 80px 118px;
    background: #0153FF;
    border-radius: 80px;
    max-width: 1280px;

    & h2 {
      font-weight: 600;
      font-size: 56px;
      line-height: 90px;
      letter-spacing: -0.04em;
      color: #FFFEFC;
    }

    & p {
      color: #FFFEFC;
      margin-bottom: 23px;
      max-width: 640px;
    }
  }

  &__bg {
    position: absolute;
    right: -290px;
    top: -20px;
    width: 380px;
    height: 408px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__wp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -40px;
  }

  &__item {
    margin-right: 40px;
    width: calc(100% - 565px);

    &:first-child {
      width: 285px;
    }

    &:last-child {
      width: 200px;
    }

    & input {
      background: rgba(255, 254, 252, 0.3);
      border-radius: 20px;
      height: 84px;
      padding: 20px 20px 20px 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFEFC;
      font-family: 'Proxima Nova Lt';

      &::-webkit-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt';
      }

      &::-moz-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt';
      }

      &:-ms-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt';
      }

      &::-ms-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt';
      }

      &::placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt';
      }
    }
  }

  &__btn {
    background: #FFFEFC;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0153FF;
    font-family: 'Proxima Nova Lt';
    height: 84px;
    width: 100%;

    &:hover {
      background: rgba(255, 254, 252, 0.3);
      color: #fffefc;
    }
  }
}


@media (max-width: 1639px) {
  .steps__item:nth-child(7) {
    margin: 0 0 48px auto;
    padding-left: 888px;
    margin-top: -18px;
  }
}

@media (max-width: 1539px) {
  .steps__item:nth-child(4) {
    margin-bottom: 15px;
  }
  .steps__item:nth-child(5) {
    padding-left: 668px;
  }
  .steps__item:nth-child(6) {
    padding-left: 640px;
    margin: 0 auto 50px auto;
  }
  .steps__item:nth-child(7) {
    padding-left: 760px;
  }
  .steps__item:nth-child(8) {
    margin-top: -110px;
  }
}

@media (max-width: 1439px) {
  .steps__item:nth-child(4) {
    padding-left: 796px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .steps__item:nth-child(6) {
    padding-left: 618px;
    margin: 0 auto 80px auto;
  }
  .steps__item:nth-child(7) {
    padding-left: 595px;
    padding-left: 620px;
  }
  .steps__item:nth-child(8) {
    margin-top: -13px;
  }
  .steps__item:nth-child(5) {
    padding-left: 740px;
  }
  .steps__item {
    padding-bottom: 40px;
  }
  .homescreen__title {
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 15px;
  }
  .homescreen__note {
    font-size: 30px;
    line-height: 32px;
    max-width: 660px;
  }
  .homescreen {
    padding: 140px 0 82px 0;
    margin-bottom: 160px;
  }
  .homescreen .line_1 {
    top: 200px;
  }
  .homescreen .line_2 {
    top: 42%;
  }
  .about .circle_1 {
    left: 0;
  }
  .about .circle_3 {
    right: 0;
  }
  .about {
    padding: 0 0 300px 0;
  }
  .about .line {
    z-index: 0;
  }
  .about .line_1 {
    bottom: -360px;
  }
  .about .line_2 {
    bottom: -413px;
  }
  .help h2 {
    margin-bottom: 100px;
  }
  .help__wp {
    margin-bottom: -20px;
    margin-right: -20px;
  }
  .help__icon {
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    padding: 20px;
  }
  .help__item {
    width: calc(25% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 20px 20px 28px 20px;
  }
  .block__title {
    font-size: 30px;
    line-height: 30px;
  }
  .block__img {
    width: 40%;
    margin-right: 5%;
  }
  .block__text {
    width: calc(60% - 5%);
  }
  .block:nth-child(2) .block__img {
    margin-left: 5%;
  }
  .form__block h2 {
    font-size: 50px;
    line-height: 80px;
  }
  .form__block {
    padding: 46px 107px 60px 98px;
    background: #0153ff;
    border-radius: 60px;
    max-width: 1280px;
  }
  .form__item input {
    padding: 20px;
  }
}

@media (max-width: 1339px) {
  .steps__item:nth-child(8) {
    margin-top: -46px;
  }

}

@media (max-width: 1239px) {
  .form {
    margin-bottom: 200px;
  }
  .form__block {
    padding: 36px 57px 40px 48px;
  }
  .form__bg {
    display: none;
  }
  .form__item input {
    height: 64px;
  }
  .form__btn {
    height: 64px;
  }
  .form__block h2 {
    font-size: 40px;
    line-height: 60px;
  }
  .block__title {
    font-size: 24px;
    line-height: 24px;
  }
  .help__item {
    width: calc(50% - 20px);
  }
  .help h2 {
    margin-bottom: 60px;
  }
  .steps {
    margin-bottom: 150px;
  }
  .steps__line {
    display: none;
  }
  .steps__wp {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 2px;
      height: 50%;
      background: #DA49D9;
      top: 0;
      z-index: -1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 2px;
      height: 50%;
      background: #CDEDFF;
      z-index: -1;
    }
  }
  .steps__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .steps__text {
    margin-right: 393px;
  }
  .steps__item:first-child {
    padding-left: 0;
  }
  .steps__item:nth-child(2) {
    padding-left: 0;
    margin-top: 0;
  }
  .steps__item:nth-child(3) {
    padding-left: 0;
    margin-top: 0;
  }
  .steps__item:nth-child(4) {
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  .steps__item:nth-child(5) {
    margin-bottom: 0;
    padding-left: 0;
  }
  .steps__item:nth-child(6) {
    padding-left: 0;
    margin-bottom: 0;
    padding-bottom: 50px;
  }
  .steps__item:nth-child(7) {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .steps__item:nth-child(8) {
    padding-left: 0;
    margin-top: 0;
    margin-right: 0;
  }
  .steps__item:nth-last-child(-n + 4) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    .steps__text {
      margin-left: 393px;
      margin-right: 0;
    }
    .steps__icon {
      right: auto;
      left: -70px;
    }
  }
  .steps__text {
    max-width: 300px;
  }
  .steps__item:nth-child(3) .steps__icon {
    left: auto;
  }
  .steps__item:nth-child(4) .steps__icon {
    left: auto;
    right: -80px;
  }
  .steps__item {
    padding-left: 200px;
  }
  .steps__item:nth-child(6) .steps__text {
    max-width: 300px;
  }
  .steps__item:nth-child(7) .steps__icon {
    bottom: -10px;
  }
  .steps__item:nth-child(3) .steps__icon {
    bottom: -10px;
  }
  .steps__item:nth-child(2) .steps__icon {
    bottom: -10px;
  }
  .steps__item:nth-child(8) .steps__icon {
    bottom: -10px;
  }
  .steps__item:nth-child(4) .steps__icon {
    bottom: -10px;
  }
  .steps__text {
    width: 100%;
  }
  .steps__icon {
    right: -70px;
    left: auto;
    bottom: -10px;
  }
  .video__title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .about {
    padding: 0 0 200px 0;
  }
  .about p {
    font-size: 20px;
    line-height: 40px;
  }
  .homescreen__title {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .homescreen__note {
    font-size: 25px;
    line-height: 29px;
    max-width: 560px;
  }
  .homescreen .line_1 {
    top: 125px;
  }
  .homescreen .line_2 {
    top: 33%;
  }
  .homescreen {
    padding: 115px 0 40px 0;
    margin-bottom: 110px;
  }
  .homescreen__img_text {
    right: 0px;
    bottom: 53px;
    padding: 5px 15px;
    font-weight: 600;
    line-height: 24px;
    font-size: 13px;
  }

  .homescreen__text {
    margin-top: 0;
  }
  .homescreen .circle_3 {
    right: 0;
  }
  .homescreen .circle_2 {
    right: 0;
  }
  .numbers__block {
    font-size: 50px;
    line-height: 50px;
  }
  .numbers__info p {
    font-size: 13px;
    line-height: 20px;
  }
  .title p {
    font-size: 30px;
    line-height: 40px;
    max-width: 700px;
  }
  .block__heading {
    margin-bottom: 60px;
  }
}

@media (max-width: 1140px) {
  .form {
    margin-bottom: 100px;
  }
  .help h2 {
    margin-bottom: 40px;
  }
  .title p {
    font-size: 20px;
    line-height: 30px;
    max-width: 600px;
  }
  .steps {
    margin-bottom: 100px;
  }
  .about {
    padding: 0 0 100px 0;
  }
  .line {
    display: none;
  }
  .numbers__block {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .numbers__info p {
    font-size: 11px;
    line-height: 16px;
  }
  .numbers__block {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 5px;
  }
  .numbers__wp {
    margin-bottom: 20px;
  }
  .block__wp {
    margin-bottom: 50px;
  }
  .gallery__slider {
    padding-bottom: 50px;
    margin-bottom: 60px;
  }
  .block__more a {
    padding: 20px 35px;
  }
  .block:nth-child(3) .gallery__slider {
    margin-bottom: 60px;
  }
  .block:nth-child(2) .gallery__slider {
    margin-bottom: 60px;
  }
}

@media (max-width: 1023px) {
  .form__block h2 {
    font-size: 30px;
    line-height: 50px;
  }
  .form__block {
    border-radius: 40px;
  }
  .form__wp {
    margin-right: -20px;
  }
  .form__item {
    width: calc(100% - 430px);
  }
  .form__item:first-child {
    width: 220px;
  }
  .form__item:last-child {
    width: 150px;
  }
  .form__item {
    margin-right: 20px;
  }
  .form__block p {
    margin-bottom: 20px;
  }
  .form {
    margin-bottom: 60px;
  }
  .block:nth-child(3) .gallery__slider {
    margin-bottom: 30px;
  }
  .block:nth-child(2) .gallery__slider {
    margin-bottom: 30px;
  }
  .gallery__slider {
    margin-bottom: 30px;
  }
  .block__heading {
    margin-bottom: 30px;
  }
  .block__wp {
    display: block;
  }
  .block__text ul li {
    min-height: auto;
  }
  .block__text ul:before {
    bottom: 130px;
  }
  .block__img {
    display: none;
  }
  .block__text {
    width: 100%;
  }
  .block__wp {
    margin-bottom: 30px;
  }
  .block__title {
    font-size: 20px;
    line-height: 20px;
  }
  .title p {
    font-size: 18px;
    line-height: 28px;
    max-width: 550px;
  }
  .steps {
    margin-bottom: 60px;
  }
  .video__bg {
    left: 12%;
    top: 41px;
    width: 250px;
    height: 250px;
  }
  .arrowAnim_1 {
    left: -200px;
  }
  .arrowAnim_2 {
    right: -200px;
  }
  .video__wp {
    max-width: 600px;
    height: 350px;
  }
  .video__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .video p {
    margin: 0 auto 20px auto;
  }
  .numbers__wp {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  .numbers__block {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .numbers__info {
    text-align: center;
    margin: 0 auto;

    & p {

    }
  }
  .numbers__item {
    text-align: center;
    margin-bottom: 15px;
    width: calc(33.3% - 10px);
    margin-right: 10px;
  }
  .numbers__wp {
    margin-right: -10px;
  }
  .homescreen__title {
    font-size: 40px;
    line-height: 40px;
  }
  .homescreen__note {
    font-size: 22px;
    line-height: 24px;
    max-width: 490px;
  }
  .homescreen__img_text {
    line-height: 18px;
    font-size: 12px;
    padding: 5px 8px;
  }
  .homescreen {
    padding: 115px 0 40px 0;
    margin-bottom: 67px;
  }
  .about {
    padding: 0 0 60px 0;
  }
  .about p {
    font-size: 18px;
    line-height: 35px;
    max-width: 80%;
    margin: 0 auto;
  }
  .block__heading h2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .form__block h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .form__block {
    padding: 30px 20px;
    border-radius: 20px;
  }
  .form__wp {
    display: block;
    margin-right: 0;
  }
  .form__item:first-child {
    width: 100%;
  }
  .form__item:last-child {
    width: 100%;
    margin-bottom: 0;
  }
  .form__item input {
    padding: 10px;
    height: 44px;
    font-size: 14px;
    border-radius: 10px;
    &::-webkit-input-placeholder {
      font-size: 14px;
    }
    &::-moz-placeholder {
      font-size: 14px;
    }
    &:-ms-input-placeholder {
      font-size: 14px;
    }
    &::-ms-input-placeholder {
      font-size: 14px;
    }
    &::placeholder {
      font-size: 14px;
    }
  }
  .form__btn {
    height: 44px;
    font-size: 14px;
    border-radius: 10px;
  }
  .form__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .help__item {
    padding: 15px 10px;
    border-radius: 10px;
  }
  .help__icon {
    border-radius: 10px;
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
    padding: 10px;
  }
  .title p {
    font-size: 16px;
    line-height: 25px;
    max-width: 550px;
  }
  .steps__wp:before {
    display: none;
  }
  .steps__wp:after {
    display: none;
  }
  .steps__text {
    margin-right: 0;
  }
  .steps__item {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .steps__item:nth-last-child(-n+4) {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .steps__item:nth-last-child(-n+4) .steps__text {
    margin-left: 0;
  }
  .video__play {
    width: 60px;
    height: 60px;
  }
  .video__bg {
    left: 50%;
    -webkit-transform: translateX(-90%);
    -ms-transform: translateX(-90%);
    transform: translateX(-90%);
    top: 26px;
    width: 225px;
    height: 225px;
  }
  .video__wp {
    max-width: 90%;
    height: 300px;
  }
  .about p {
    font-size: 16px;
    line-height: 30px;
    max-width: 90%;
    margin: 0 auto;
  }
  .homescreen__title {
    font-size: 30px;
    line-height: 30px;
  }
  .homescreen__note {
    font-size: 18px;
    line-height: 21px;
  }
  .homescreen__text {
    margin-right: 20px;
  }
  .homescreen__img {
    width: calc(47% - 20px);
  }
  .homescreen__img_text {
    line-height: 18px;
    font-size: 10px;
    border-radius: 5px;
    padding: 5px 8px;
  }
}

@media (max-width: 567px) {
  .video__bg {
    left: 50%;
    -webkit-transform: translateX(-90%);
    -ms-transform: translateX(-90%);
    transform: translateX(-90%);
    top: 54px;
    width: 145px;
    height: 128px;
  }
  .gallery__img {
    margin-bottom: 20px;
  }
  .help__item {
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .help__wp {
    display: block;
    margin-right: 0;
    margin-bottom: 0;
  }
  .help__icon {
    margin: 0 0 15px 0;
  }
  .help__text {
    text-align: left;
  }
  .steps__icon {
    position: relative;
    left: auto!important;
    right: auto!important;
    bottom: auto!important;
    margin: 0 auto;
  }
  .steps__text p {
    text-align: center;
    margin-bottom: 15px;
  }
  .steps__item {
    padding-bottom: 0!important;
    margin-bottom: 20px!important;
  }
  .steps__item:nth-last-child(-n+4) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .steps__item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .video__wp {
    height: 250px;
  }
  .numbers__item {
    width: calc(50% - 10px);
  }
  .homescreen {
    margin-bottom: 60px;
  }
  .about p {
    max-width: 100%;
  }
  .about .circle_1 {
    width: 30px;
    height: 30px;
  }
  .homescreen .container {
    display: block;
  }
  .homescreen__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .homescreen__img {
    text-align: center;
  }
  .homescreen .circle_1 {
    top: -38px;
  }
  .homescreen__img {
    width: 100%;
    height: 300px;

    & img {
      height: auto;
      max-height: 100%;
      width: auto;
    }
  }
  .homescreen__img_text {
    right: auto;
    left: 50%;
    white-space: nowrap;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}