/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

.header {
  background: #FFFFFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  padding: 24px 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;

  &.sticky {
    -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
    box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
    padding: 12px 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }

  & .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__logo {
    width: 190px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .wrap-nav {
    margin-left: auto;
  }

  & .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &-item {
      margin-right: 35px;

      &:last-child {
        margin-right: 0;

        & a {
          color: #0A3FC7;
          background: rgba(1, 83, 255, 0.16);
        }
      }

      & a {
        padding: 8px 16px;
        font-weight: 600;
        font-size: 15px;
        line-height: 28px;
        color: #1A1A1D;
        border-radius: 10px;

        &:hover {
          color: #DA49D9;
        }
      }
    }
  }
}

.open-nav {
  margin-left: auto;
  width: 35px;
  height: 20px;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  & .burger-line {
    display: block;
    width: 100%;
    height: 2px;
    background: #0A3FC7;
  }
}

.close-nav {
  width: 40px;
  height: 40px;
  display: none;
  font-size: 30px;
  color: #1A1A1D;
  padding: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.overlay {
  z-index: 999;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255,255,255,.5);
  &.open {
    display: block;
  }
}

@media (max-width: 1439px) {
  .header .menu-item {
    margin-right: 20px;
  }
  .header .menu-item a {
    padding: 5px 12px;
    font-size: 14px;
  }
}

@media (max-width: 1239px) {
  .header__logo {
    width: 160px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header .menu-item a {
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 7px;
  }
}

@media (max-width: 1139px) {
  .header__logo {
    width: 140px;
    height: 25px;
  }
}

@media (max-width: 1023px) {
  .header {
    .wrap-nav {
      z-index: 9999;
      position: fixed;
      right: 0;
      top: 0;
      width: 360px;
      max-width: 80%;
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
      padding: 50px 20px 20px 30px;
      background: #fffefc;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      height: 100vh;
      &.open {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
      }
    }
    .menu {
      display: block;
      &-item {
        margin-right: 0;
        margin-bottom: 15px;
        & a {
          font-size: 15px;
        }
      }
    }
  }
  .open-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .close-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}