/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */
@import url(../../../node_modules/swiper/css/swiper.min.css);
@import url(../../../node_modules/normalize.css/normalize.css);
@import url(../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@font-face {
  font-family: 'Proxima Nova Lt';
  src: url("../fonts/ProximaNova-Semibold.eot");
  src: local("../fonts/Proxima Nova Semibold"), local("ProximaNova-Semibold"), url("../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: '../fonts/Proxima Nova Cn Lt';
  src: url("../fonts/ProximaNovaCond-LightIt.eot");
  src: local("../fonts/Proxima Nova Condensed Light Italic"), local("ProximaNovaCond-LightIt"), url("../fonts/ProximaNovaCond-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-LightIt.woff") format("woff"), url("../fonts/ProximaNovaCond-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url("../fonts/ProximaNova-LightIt.eot");
  src: local("../fonts/Proxima Nova Light Italic"), local("ProximaNova-LightIt"), url("../fonts/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-LightIt.woff") format("woff"), url("../fonts/ProximaNova-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url("../fonts/ProximaNovaCond-Regular.eot");
  src: local("../fonts/Proxima Nova Condensed Regular"), local("ProximaNovaCond-Regular"), url("../fonts/ProximaNovaCond-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Regular.woff") format("woff"), url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Th';
  src: url("../fonts/ProximaNova-Extrabld.eot");
  src: local("../fonts/Proxima Nova Extrabold"), local("ProximaNova-Extrabld"), url("../fonts/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Extrabld.woff") format("woff"), url("../fonts/ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Bl';
  src: url("../fonts/ProximaNovaCond-Black.eot");
  src: local("../fonts/Proxima Nova Condensed Black"), local("ProximaNovaCond-Black"), url("../fonts/ProximaNovaCond-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Black.woff") format("woff"), url("../fonts/ProximaNovaCond-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url("../fonts/ProximaNovaCond-ExtrabldIt.eot");
  src: local("../fonts/Proxima Nova Condensed Extrabold Italic"), local("ProximaNovaCond-ExtrabldIt"), url("../fonts/ProximaNovaCond-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-ExtrabldIt.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("../fonts/ProximaNova-Regular.eot");
  src: local("../fonts/Proxima Nova Regular"), local("ProximaNova-Regular"), url("../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Th';
  src: url("../fonts/ProximaNovaExCn-Thin.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Thin"), local("ProximaNovaExCn-Thin"), url("../fonts/ProximaNovaExCn-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Thin.woff") format("woff"), url("../fonts/ProximaNovaExCn-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url("../fonts/ProximaNovaCond-Semibold.eot");
  src: local("../fonts/Proxima Nova Condensed Semibold"), local("ProximaNovaCond-Semibold"), url("../fonts/ProximaNovaCond-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Semibold.woff") format("woff"), url("../fonts/ProximaNovaCond-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Lt';
  src: url("../fonts/ProximaNovaExCn-SemiboldIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Semibold Italic"), local("ProximaNovaExCn-SemiboldIt"), url("../fonts/ProximaNovaExCn-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Lt';
  src: url("../fonts/ProximaNovaExCn-Semibold.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Semibold"), local("ProximaNovaExCn-Semibold"), url("../fonts/ProximaNovaExCn-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Semibold.woff") format("woff"), url("../fonts/ProximaNovaExCn-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Th';
  src: url("../fonts/ProximaNova-ThinIt.eot");
  src: local("../fonts/Proxima Nova Thin Italic"), local("ProximaNova-ThinIt"), url("../fonts/ProximaNova-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-ThinIt.woff") format("woff"), url("../fonts/ProximaNova-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url("../fonts/ProximaNovaCond-Thin.eot");
  src: local("../fonts/Proxima Nova Condensed Thin"), local("ProximaNovaCond-Thin"), url("../fonts/ProximaNovaCond-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Thin.woff") format("woff"), url("../fonts/ProximaNovaCond-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url("../fonts/ProximaNovaCond-RegularIt.eot");
  src: local("../fonts/Proxima Nova Condensed Regular Italic"), local("ProximaNovaCond-RegularIt"), url("../fonts/ProximaNovaCond-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-RegularIt.woff") format("woff"), url("../fonts/ProximaNovaCond-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Th';
  src: url("../fonts/ProximaNovaExCn-ThinIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Thin Italic"), local("ProximaNovaExCn-ThinIt"), url("../fonts/ProximaNovaExCn-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-ThinIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url("../fonts/ProximaNova-SemiboldIt.eot");
  src: local("../fonts/Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"), url("../fonts/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("../fonts/ProximaNova-RegularIt.eot");
  src: local("../fonts/Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"), url("../fonts/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-RegularIt.woff") format("woff"), url("../fonts/ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url("../fonts/ProximaNovaCond-Extrabld.eot");
  src: local("../fonts/Proxima Nova Condensed Extrabold"), local("ProximaNovaCond-Extrabld"), url("../fonts/ProximaNovaCond-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Extrabld.woff") format("woff"), url("../fonts/ProximaNovaCond-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Th';
  src: url("../fonts/ProximaNovaExCn-ExtrabldIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Extrabold Italic"), local("ProximaNovaExCn-ExtrabldIt"), url("../fonts/ProximaNovaExCn-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-ExtrabldIt.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Bl';
  src: url("../fonts/ProximaNovaExCn-BlackIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Black Italic"), local("ProximaNovaExCn-BlackIt"), url("../fonts/ProximaNovaExCn-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-BlackIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Lt';
  src: url("../fonts/ProximaNovaExCn-LightIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Light Italic"), local("ProximaNovaExCn-LightIt"), url("../fonts/ProximaNovaExCn-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-LightIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Th';
  src: url("../fonts/ProximaNovaCond-ThinIt.eot");
  src: local("../fonts/Proxima Nova Condensed Thin Italic"), local("ProximaNovaCond-ThinIt"), url("../fonts/ProximaNovaCond-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-ThinIt.woff") format("woff"), url("../fonts/ProximaNovaCond-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Lt';
  src: url("../fonts/ProximaNovaExCn-Light.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Light"), local("ProximaNovaExCn-Light"), url("../fonts/ProximaNovaExCn-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Light.woff") format("woff"), url("../fonts/ProximaNovaExCn-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url("../fonts/ProximaNovaCond-BoldIt.eot");
  src: local("../fonts/Proxima Nova Condensed Bold Italic"), local("ProximaNovaCond-BoldIt"), url("../fonts/ProximaNovaCond-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-BoldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Th';
  src: url("../fonts/ProximaNovaExCn-Extrabld.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Extrabold"), local("ProximaNovaExCn-Extrabld"), url("../fonts/ProximaNovaExCn-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Extrabld.woff") format("woff"), url("../fonts/ProximaNovaExCn-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url("../fonts/ProximaNovaExCn-BoldIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Bold Italic"), local("ProximaNovaExCn-BoldIt"), url("../fonts/ProximaNovaExCn-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-BoldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Bl';
  src: url("../fonts/ProximaNova-Black.eot");
  src: local("../fonts/Proxima Nova Black"), local("ProximaNova-Black"), url("../fonts/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Black.woff") format("woff"), url("../fonts/ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("../fonts/ProximaNova-Bold.eot");
  src: local("../fonts/Proxima Nova Bold"), local("ProximaNova-Bold"), url("../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url("../fonts/ProximaNovaExCn-Bold.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Bold"), local("ProximaNovaExCn-Bold"), url("../fonts/ProximaNovaExCn-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Bold.woff") format("woff"), url("../fonts/ProximaNovaExCn-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Th';
  src: url("../fonts/ProximaNovaT-Thin.eot");
  src: local("../fonts/Proxima Nova Thin"), local("ProximaNovaT-Thin"), url("../fonts/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaT-Thin.woff") format("woff"), url("../fonts/ProximaNovaT-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("../fonts/ProximaNova-BoldIt.eot");
  src: local("../fonts/Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"), url("../fonts/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-BoldIt.woff") format("woff"), url("../fonts/ProximaNova-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url("../fonts/ProximaNovaCond-Bold.eot");
  src: local("../fonts/Proxima Nova Condensed Bold"), local("ProximaNovaCond-Bold"), url("../fonts/ProximaNovaCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Bold.woff") format("woff"), url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Bl';
  src: url("../fonts/ProximaNovaExCn-Black.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Black"), local("ProximaNovaExCn-Black"), url("../fonts/ProximaNovaExCn-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Black.woff") format("woff"), url("../fonts/ProximaNovaExCn-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url("../fonts/ProximaNovaExCn-RegularIt.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Regular Italic"), local("ProximaNovaExCn-RegularIt"), url("../fonts/ProximaNovaExCn-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-RegularIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url("../fonts/ProximaNovaCond-Light.eot");
  src: local("../fonts/Proxima Nova Condensed Light"), local("ProximaNovaCond-Light"), url("../fonts/ProximaNovaCond-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Light.woff") format("woff"), url("../fonts/ProximaNovaCond-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Th';
  src: url("../fonts/ProximaNova-ExtrabldIt.eot");
  src: local("../fonts/Proxima Nova Extrabold Italic"), local("ProximaNova-ExtrabldIt"), url("../fonts/ProximaNova-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Cn Bl';
  src: url("../fonts/ProximaNovaCond-BlackIt.eot");
  src: local("../fonts/Proxima Nova Condensed Black Italic"), local("ProximaNovaCond-BlackIt"), url("../fonts/ProximaNovaCond-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-BlackIt.woff") format("woff"), url("../fonts/ProximaNovaCond-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url("../fonts/ProximaNovaExCn-Regular.eot");
  src: local("../fonts/Proxima Nova Extra Condensed Regular"), local("ProximaNovaExCn-Regular"), url("../fonts/ProximaNovaExCn-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Regular.woff") format("woff"), url("../fonts/ProximaNovaExCn-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url("../fonts/ProximaNova-Light.eot");
  src: local("../fonts/Proxima Nova Light"), local("ProximaNova-Light"), url("../fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Light.woff") format("woff"), url("../fonts/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Proxima Nova Cn Lt';
  src: url("../fonts/ProximaNovaCond-SemiboldIt.eot");
  src: local("../fonts/Proxima Nova Condensed Semibold Italic"), local("ProximaNovaCond-SemiboldIt"), url("../fonts/ProximaNovaCond-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic; }

@font-face {
  font-family: 'Proxima Nova Bl';
  src: url("../fonts/ProximaNova-BlackIt.eot");
  src: local("../fonts/Proxima Nova Black Italic"), local("ProximaNova-BlackIt"), url("../fonts/ProximaNova-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-BlackIt.woff") format("woff"), url("../fonts/ProximaNova-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic; }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
* {
  position: relative;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

a, button, span {
  display: inline-block; }

input, textarea {
  display: block;
  width: 100%; }

table {
  width: 100%;
  border-collapse: collapse; }

a {
  background-color: transparent;
  text-decoration: none; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

a, button {
  outline: 0;
  cursor: pointer; }

li {
  list-style: none; }

button {
  background: 0 0;
  border: none; }

a, button, input, textarea {
  color: #000;
  font-weight: inherit;
  -webkit-appearance: none;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease; }

:after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input {
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input[type=number] {
  -moz-appearance: textfield; }

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block; }

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.full-img img {
  width: 100%; }

figure {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: .75em;
  opacity: .8; }

[hidden] {
  display: none !important; }

.img-full {
  width: 100%; }

.clearfix:after {
  content: '';
  clear: both;
  display: block; }

* div, span, a, :after, :before {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }

h1, .h1 {
  margin: 0; }

h2, .h2 {
  margin: 0;
  font-weight: 600;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -0.04em;
  color: #1A1A1D;
  max-width: 990px; }
  h2 span, .h2 span {
    color: #0153FF; }
  @media (max-width: 1439px) {
    h2, .h2 {
      font-size: 70px;
      line-height: 70px; } }
  @media (max-width: 1239px) {
    h2, .h2 {
      font-size: 50px;
      line-height: 50px; } }
  @media (max-width: 1139px) {
    h2, .h2 {
      font-size: 40px;
      line-height: 40px; } }
  @media (max-width: 1023px) {
    h2, .h2 {
      font-size: 30px;
      line-height: 30px; } }

h3, .h3 {
  margin: 0; }

h4, .h4 {
  margin: 0; }

h5, .h5 {
  margin: 0; }

h6, .h6 {
  margin: 0; }

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: #1A1A1D;
  background: #FFFEFC; }
  @media (max-width: 735px) {
    body {
      font-size: 13px;
      line-height: 24px; } }

.wrapper {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }

header {
  width: 100%; }

main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  display: block; }

footer {
  margin: auto auto 0 auto;
  width: 100%; }

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  z-index: 2; }
  @media (max-width: 1239px) {
    .container,
    .container-fluid {
      padding: 0 40px; } }
  @media (max-width: 1023px) {
    .container,
    .container-fluid {
      padding: 0 20px; } }

.container {
  max-width: 1680px; }

.container-fluid {
  max-width: 1920px; }

.btn-row {
  text-align: center; }
  .btn-row .btn {
    margin: 10px; }

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -20px; }
  .column .column-2 {
    width: 50%;
    padding: 10px 20px; }
  .column .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px; }

.fancybox-navigation {
  position: static; }

.swiper-button {
  outline: none; }
  .swiper-button.swiper-button-prev {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1); }
  .swiper-button:before, .swiper-button:after {
    display: none; }

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px; }
  .swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    outline: none; }

input::-webkit-input-placeholder {
  color: #000; }

input::-moz-placeholder {
  color: #000; }

input:-moz-placeholder {
  color: #000; }

input:-ms-input-placeholder {
  color: #000; }

textarea::-webkit-input-placeholder {
  color: #000; }

textarea::-moz-placeholder {
  color: #000; }

textarea:-moz-placeholder {
  color: #000; }

textarea:-ms-input-placeholder {
  color: #000; }

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus::-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-ms-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

textarea:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

/*modal*/
.modal {
  display: none;
  max-width: 100%;
  background-color: #fff;
  overflow: visible; }

.modal, .modal > * {
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  -ms-transform: translateY(150px);
  -o-transform: translateY(150px);
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s; }

.fancybox-slide--current .modal, .fancybox-slide--current .modal > * {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
.header {
  background: #FFFFFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  padding: 24px 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }
  .header.sticky {
    -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
    box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
    padding: 12px 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s; }
  .header .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .header__logo {
    width: 190px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .header__logo img {
      max-width: 100%;
      max-height: 100%; }
  .header .wrap-nav {
    margin-left: auto; }
  .header .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .header .menu-item {
      margin-right: 35px; }
      .header .menu-item:last-child {
        margin-right: 0; }
        .header .menu-item:last-child a {
          color: #0A3FC7;
          background: rgba(1, 83, 255, 0.16); }
      .header .menu-item a {
        padding: 8px 16px;
        font-weight: 600;
        font-size: 15px;
        line-height: 28px;
        color: #1A1A1D;
        border-radius: 10px; }
        .header .menu-item a:hover {
          color: #DA49D9; }

.open-nav {
  margin-left: auto;
  width: 35px;
  height: 20px;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .open-nav .burger-line {
    display: block;
    width: 100%;
    height: 2px;
    background: #0A3FC7; }

.close-nav {
  width: 40px;
  height: 40px;
  display: none;
  font-size: 30px;
  color: #1A1A1D;
  padding: 7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.overlay {
  z-index: 999;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5); }
  .overlay.open {
    display: block; }

@media (max-width: 1439px) {
  .header .menu-item {
    margin-right: 20px; }
  .header .menu-item a {
    padding: 5px 12px;
    font-size: 14px; } }

@media (max-width: 1239px) {
  .header__logo {
    width: 160px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .header .menu-item a {
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 7px; } }

@media (max-width: 1139px) {
  .header__logo {
    width: 140px;
    height: 25px; } }

@media (max-width: 1023px) {
  .header .wrap-nav {
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0;
    width: 360px;
    max-width: 80%;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    padding: 50px 20px 20px 30px;
    background: #fffefc;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    height: 100vh; }
    .header .wrap-nav.open {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s; }
  .header .menu {
    display: block; }
    .header .menu-item {
      margin-right: 0;
      margin-bottom: 15px; }
      .header .menu-item a {
        font-size: 15px; }
  .open-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .close-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; } }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
.footer {
  padding-bottom: 287px; }
  .footer .line {
    margin: 0 auto;
    width: 2060px; }
    .footer .line_2:before {
      top: 210px; }
    .footer .line_1:after {
      position: absolute;
      content: '';
      display: block !important;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(46.79deg);
      width: 39px;
      height: 59px;
      right: -8px;
      bottom: 16px;
      top: auto; }
  .footer__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(25% - 60px);
    margin-right: 60px; }
    .footer__block.hidden {
      display: none; }
    .footer__block:first-child {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; }
    .footer__block:last-child {
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end; }
  .footer__logo {
    width: 190px;
    height: 36px;
    margin-bottom: 16px;
    text-align: left; }
    .footer__logo img {
      max-width: 100%;
      max-height: 100%; }
  .footer__wp {
    margin-right: -60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .footer .menu-item {
    margin-bottom: 16px; }
    .footer .menu-item:last-child {
      margin-bottom: 0; }
    .footer .menu-item a:hover {
      color: #0153FF; }
  .footer__address {
    max-width: 181px; }
    .footer__address p:first-child {
      font-weight: 600;
      margin-bottom: 16px; }
  .footer__btn a {
    background: rgba(1, 83, 255, 0.16);
    border-radius: 10px;
    width: 197px;
    padding: 8px 8px 8px 12px;
    font-weight: 600;
    color: #0A3FC7; }
    .footer__btn a svg {
      fill: #0A3FC7; }
    .footer__btn a:hover {
      color: #DA49D9; }
      .footer__btn a:hover svg {
        fill: #DA49D9; }
    .footer__btn a span {
      vertical-align: middle;
      margin-right: 10px;
      width: 15px;
      margin-top: -8px;
      height: 16px; }
  .footer__note {
    color: #5A5B61;
    margin-bottom: 69px;
    max-width: 260px; }
  .footer .line:after {
    display: none; }
  .footer .line_1 {
    bottom: -12px;
    width: 2057px; }
    @media (max-width: 1998px) {
      .footer .line_1 {
        left: -50px; } }
  .footer .line_2 {
    width: 1723px;
    bottom: 20px; }
    @media (max-width: 1998px) {
      .footer .line_2 {
        left: -50px; } }

@media (max-width: 1239px) {
  .footer__logo {
    margin-right: 100px; }
  .footer {
    padding-bottom: 150px; } }

@media (max-width: 1139px) {
  .footer {
    padding-bottom: 100px; }
  .footer__block {
    width: calc(25% - 25px);
    margin-right: 25px; }
  .footer__wp {
    margin-right: -25px; } }

@media (max-width: 1023px) {
  .footer__logo {
    margin: 0 auto; }
  .footer__wp {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px; }
  .footer__year {
    display: none; }
  .footer .menu-item {
    margin-bottom: 5px; }
  .footer__logo {
    margin-bottom: 10px; }
  .footer__note {
    margin: 0 auto; }
  .footer__block {
    width: calc(50% - 15px);
    margin-right: 15px;
    margin-bottom: 20px; }
    .footer__block:nth-child(4) {
      width: 100%; }
    .footer__block:first-child {
      width: 100%;
      text-align: center; }
    .footer__block:last-child {
      margin-bottom: 0; }
    .footer__block.hidden {
      display: block;
      text-align: center;
      width: 100%; }
      .footer__block.hidden .footer__year {
        display: block; }
  .footer {
    padding-bottom: 30px; }
  .footer__logo {
    width: 160px;
    height: 30px; }
  .footer .menu-item {
    margin-bottom: 10px; }
  .footer__address p:first-child {
    margin-bottom: 10px; } }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
.homescreen {
  padding: 164px 0 82px 0;
  margin-bottom: 232px; }
  .homescreen .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .homescreen__text {
    width: 53%;
    margin-right: 84px;
    margin-top: -140px; }
  .homescreen__img {
    width: calc(47% - 84px); }
    .homescreen__img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
    .homescreen__img_text {
      background: #FFFEFC;
      -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
      box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.25), 0px 8px 12px rgba(111, 144, 209, 0.25);
      border-radius: 12px;
      position: absolute;
      right: 53px;
      bottom: 73px;
      padding: 10px 20px;
      font-weight: 600;
      line-height: 28px;
      color: #0153FF; }
  .homescreen__title {
    font-weight: 600;
    font-size: 90px;
    line-height: 90px;
    letter-spacing: -0.04em;
    color: #1A1A1D;
    margin-bottom: 20px; }
  .homescreen__note {
    font-weight: bold;
    font-size: 34px;
    line-height: 37px;
    color: #0153FF;
    max-width: 760px; }
  .homescreen .line_1 {
    left: 142px;
    top: 365px;
    width: 1834px;
    margin: 0 auto; }
    .homescreen .line_1:before {
      display: none; }
    .homescreen .line_1 .circle_1 {
      bottom: 245px;
      left: -17px;
      background: #DA49D9;
      width: 50px;
      height: 50px;
      position: absolute;
      z-index: 2; }
  .homescreen .line_2 {
    width: 1996px;
    top: 55%;
    margin: 0 auto; }
    @media (max-width: 1998px) {
      .homescreen .line_2 {
        left: -40px; } }
  .homescreen .circle_1 {
    background: #0153FF;
    width: 30px;
    height: 30px;
    top: -16px;
    left: 46%; }
  .homescreen .circle_2 {
    background: #DA49D9;
    width: 20px;
    height: 20px;
    right: -130px;
    top: 31px; }
  .homescreen .circle_3 {
    background: #0153FF;
    width: 80px;
    height: 80px;
    right: -160px;
    bottom: -93px; }

.about {
  padding: 0 0 600px 0; }
  .about p {
    z-index: 2;
    text-align: center;
    font-size: 24px;
    line-height: 48px; }
  .about .line_1 {
    width: 2000px;
    bottom: -215px;
    z-index: 2;
    margin: 0 auto; }
    @media (max-width: 1998px) {
      .about .line_1 {
        left: -60px; } }
  .about .line_2 {
    bottom: -213px;
    width: 2000px;
    margin: 0 auto; }
    @media (max-width: 1998px) {
      .about .line_2 {
        left: -30px; } }
  .about .circle_1 {
    background: #DA49D9;
    width: 80px;
    height: 80px;
    left: -100px;
    top: 100px; }
  .about .circle_2 {
    background: #DA49D9;
    width: 20px;
    height: 20px;
    bottom: -200px;
    left: 200px; }
  .about .circle_3 {
    background: #0153FF;
    width: 30px;
    height: 30px;
    right: -100px;
    bottom: -500px; }

.numbers__wp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-right: -30px;
  margin-bottom: 60px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.numbers__item {
  width: calc(16.6% - 30px);
  margin-right: 30px; }

.numbers__block {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 64px;
  line-height: 64px;
  color: #0153FF;
  text-transform: uppercase; }
  .numbers__block_plus {
    color: #DA49D9; }

.numbers__info {
  text-align: left;
  max-width: 174px; }

.numbers__note {
  text-align: center;
  color: #8F9099; }
  .numbers__note span {
    color: #0153FF; }

.video {
  text-align: center; }
  .video__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 40px; }
  .video p {
    max-width: 800px;
    margin: 0 auto 40px auto; }
  .video__wp {
    z-index: 6;
    text-align: left;
    padding: 33px 0;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    height: 450px; }
    .video__wp:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #6798FF;
      -webkit-box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.1), 0px 8px 12px rgba(111, 144, 209, 0.2);
      box-shadow: 0px 4px 4px rgba(111, 144, 209, 0.1), 0px 8px 12px rgba(111, 144, 209, 0.2);
      border-radius: 10px; }
    .video__wp img {
      z-index: 2;
      max-width: 100%;
      max-height: 100%; }
  .video__bg {
    position: absolute;
    left: 16%;
    top: 63px;
    z-index: 4; }
  .video__play {
    z-index: 5;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #1A1A1D;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 10px 10px 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
    .video__play img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto; }
    .video__play:hover {
      background: #FF3F5D; }

.arrowAnim {
  width: 300px;
  height: 67px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -2; }
  .arrowAnim_2 {
    right: -300px; }
  .arrowAnim_1 {
    left: -300px;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg); }

.arrow {
  width: 67px;
  height: 218px;
  border-color: #CDEDFF transparent transparent #CDEDFF; }

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite; }

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s; }

.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s; }

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10vw);
    transform: translateX(10vw); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(7vw);
    transform: translateX(7vw); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(-7vw);
    transform: translateX(-7vw); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-10vw);
    transform: translateX(-10vw); } }

@keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10vw);
    transform: translateX(10vw); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(7vw);
    transform: translateX(7vw); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(-7vw);
    transform: translateX(-7vw); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-10vw);
    transform: translateX(-10vw); } }

.steps {
  margin-bottom: 227px; }
  .steps__line {
    position: absolute;
    left: -155px;
    top: 91px;
    z-index: -1; }
  .steps__item {
    padding-bottom: 50px; }
    .steps__item:first-child {
      padding-left: 70px; }
    .steps__item:nth-child(2) {
      padding-left: 400px;
      margin-top: -50px; }
      .steps__item:nth-child(2) .steps__icon {
        bottom: -25px; }
    .steps__item:nth-child(3) {
      padding-left: 660px;
      margin-top: 25px; }
      .steps__item:nth-child(3) .steps__icon {
        bottom: 2px;
        left: -65px; }
    .steps__item:nth-child(4) {
      padding-left: 760px;
      margin-top: 180px;
      margin-bottom: 90px; }
      .steps__item:nth-child(4) .steps__icon {
        bottom: 15px;
        left: -98px;
        -webkit-box-shadow: 0px 10px 36px rgba(218, 73, 217, 0.5);
        box-shadow: 0px 10px 36px rgba(218, 73, 217, 0.5);
        width: 70px;
        height: 70px; }
    .steps__item:nth-child(5) {
      margin: 0 auto 25px auto;
      padding-left: 635px; }
      .steps__item:nth-child(5) .steps__icon {
        bottom: -4px;
        left: -68px; }
    .steps__item:nth-child(6) {
      margin: 0 auto 10px auto;
      padding-left: 720px;
      padding-bottom: 10px; }
      .steps__item:nth-child(6) .steps__text {
        max-width: 230px; }
      .steps__item:nth-child(6) .steps__icon {
        left: -50px; }
    .steps__item:nth-child(7) {
      margin: 0 0 30px auto;
      padding-left: 994px; }
      .steps__item:nth-child(7) .steps__icon {
        bottom: -65px;
        left: -10px; }
    .steps__item:nth-child(8) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding-right: 0;
      margin-top: -140px;
      margin-right: -50px; }
      .steps__item:nth-child(8) .steps__icon {
        bottom: -65px;
        left: -10px; }
    .steps__item:nth-last-child(-n + 4) .steps__icon {
      background: -o-radial-gradient(50% 50%, 50% 50%, #BBE7FF 0%, rgba(237, 249, 255, 0) 100%);
      background: radial-gradient(50% 50% at 50% 50%, #BBE7FF 0%, rgba(237, 249, 255, 0) 100%); }
      .steps__item:nth-last-child(-n + 4) .steps__icon:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: #CDEDFF;
        width: 24px;
        height: 24px;
        border-radius: 50%; }
      .steps__item:nth-last-child(-n + 4) .steps__icon:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #FFFFFF;
        z-index: 2; }
  .steps__text p {
    max-width: 300px; }
  .steps__icon {
    position: absolute;
    bottom: -45px;
    left: -70px;
    background: #DA49D9;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .steps .circle_1 {
    background: #0153FF;
    width: 20px;
    height: 20px;
    left: 25%;
    top: 41%; }
  .steps .circle_2 {
    background: #0153FF;
    width: 50px;
    height: 50px;
    top: 29%;
    right: 27%; }
  .steps .circle_3 {
    background: #DA49D9;
    width: 20px;
    height: 20px;
    right: 30px;
    top: 107px; }
  .steps .circle_4 {
    background: #0153FF;
    width: 20px;
    height: 20px;
    right: 219px;
    top: 56%; }
  .steps .circle_5 {
    background: #DA49D9;
    width: 20px;
    height: 20px;
    left: 74px;
    bottom: 26%; }
  .steps .circle_6 {
    background: #0153FF;
    width: 30px;
    height: 30px;
    bottom: 140px;
    left: 25%; }

.title .circle_1 {
  background: #0153FF;
  width: 50px;
  height: 50px;
  top: -55px;
  left: 350px; }

.title p {
  font-weight: 500;
  font-size: 34px;
  line-height: 48px;
  text-align: center;
  color: #1A1A1D;
  max-width: 800px;
  margin: 0 auto; }

.help h2 {
  margin-bottom: 120px; }

.help__wp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -40px;
  margin-bottom: -40px; }

.help__item {
  width: calc(25% - 40px);
  margin-right: 40px;
  margin-bottom: 40px;
  border: 1px solid #CDEDFF;
  border-radius: 40px;
  padding: 40px 40px 48px 40px; }

.help__icon {
  background: #0153FF;
  border-radius: 30px;
  margin-bottom: 40px;
  width: 118px;
  height: 118px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .help__icon img {
    max-width: 100%;
    max-height: 100%; }

.block__heading {
  margin-bottom: 120px; }
  .block__heading h2 {
    margin-bottom: 40px; }
  .block__heading p {
    letter-spacing: -0.04em;
    max-width: 500px; }

.block__wp {
  margin-bottom: 107px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.block__img {
  width: 36%;
  margin-right: 11%; }
  .block__img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.block__text {
  width: calc(64% - 11%); }
  .block__text ul li {
    padding-left: 34px;
    min-height: 266px;
    padding-bottom: 40px; }
    .block__text ul li:before {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      top: 10px;
      bottom: -15px;
      width: 2px;
      background: #CDEDFF; }
    .block__text ul li:last-child:before {
      display: none; }

.block__title {
  font-weight: 600;
  font-size: 34px;
  line-height: 34px;
  color: #5A5B61;
  margin-bottom: 15px; }
  .block__title:before, .block__title:after {
    display: block;
    position: absolute;
    content: '';
    border-radius: 50%; }
  .block__title:before {
    left: -45px;
    top: -5px;
    width: 28px;
    height: 28px;
    background: #CDEDFF; }
  .block__title:after {
    left: -38px;
    top: 2px;
    background: #FFFEFC;
    -webkit-box-shadow: 0px 2px 2px rgba(11, 43, 61, 0.25);
    box-shadow: 0px 2px 2px rgba(11, 43, 61, 0.25);
    width: 14px;
    height: 14px; }

.block .line {
  z-index: -1;
  left: -230px; }
  .block .line:after, .block .line:before {
    display: none; }
  .block .line_1 {
    width: 1371px;
    top: 90px;
    right: auto; }
    @media (min-width: 1999px) {
      .block .line_1:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 60px;
        top: 0;
        bottom: 0;
        background: linear-gradient(88deg, rgba(255, 255, 255, 0) 0, #fff 0.01%, rgba(255, 255, 255, 0) 100%); } }
  .block .line_2 {
    right: auto;
    width: 940px;
    top: 82px; }
    @media (min-width: 1999px) {
      .block .line_2:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 60px;
        top: 0;
        bottom: 0;
        background: linear-gradient(88deg, rgba(255, 255, 255, 0) 0, #fff 0.01%, rgba(255, 255, 255, 0) 100%); } }

.block .circle_1 {
  background: #0153FF;
  width: 90px;
  height: 90px;
  left: -143px;
  bottom: 114px; }

.block .circle_2 {
  background: #DA49D9;
  width: 62px;
  height: 62px;
  right: -223px;
  top: 16%; }

.block__more {
  text-align: center; }
  .block__more a {
    font-family: 'Proxima Nova Lt';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFEFC;
    background: #0153FF;
    border-radius: 20px;
    padding: 32px 56px; }
    .block__more a span {
      margin-left: 16px;
      margin-bottom: -1px;
      vertical-align: middle; }
    .block__more a:hover span {
      -webkit-transform: translateX(15px);
      -ms-transform: translateX(15px);
      transform: translateX(15px); }

.block:nth-child(2) .line {
  left: auto;
  right: -230px; }
  .block:nth-child(2) .line_1:before {
    right: -23px;
    top: 27px;
    left: auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(39.13deg);
    width: 60px;
    height: 39px;
    bottom: auto; }
  .block:nth-child(2) .line_2:before {
    left: auto;
    right: -16px;
    height: 60px;
    bottom: auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(50.57deg);
    width: 60px; }

.block:nth-child(2) .block__img {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-right: 0;
  margin-left: 11%; }

.block:nth-child(2) .block__text {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.block:nth-child(2) .gallery__slider {
  margin-bottom: 78px; }

.block:nth-child(2) .line_1 {
  width: 1950px;
  top: 375px; }

.block:nth-child(2) .line_2 {
  top: 313px;
  width: 1625px; }

.block:nth-child(2) .circle_1 {
  bottom: auto;
  top: -188px;
  left: 35%;
  width: 30px;
  height: 30px; }

.block:nth-child(2) .circle_2 {
  width: 90px;
  height: 90px;
  right: -173px;
  top: 35%; }

.block:nth-child(3) .line_1 {
  width: 2000px;
  top: 488px; }

.block:nth-child(3) .line_2 {
  width: 2000px;
  top: 508px;
  z-index: -2; }
  .block:nth-child(3) .line_2:after {
    display: block !important;
    top: auto;
    bottom: 100px;
    right: -12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff 0.01%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(52.21deg);
    width: 39px;
    height: 66px; }

.block:nth-child(3) .circle_1 {
  background: #0153FF;
  width: 56px;
  height: 56px;
  left: -180px;
  top: 0; }

.block:nth-child(3) .circle_2 {
  background: #0153FF;
  width: 30px;
  height: 30px;
  right: auto;
  left: -104px;
  top: 394px; }

.block:nth-child(3) .circle_3 {
  background: #0153FF;
  width: 30px;
  height: 30px;
  right: -115px;
  top: 400px; }

.block:nth-child(3) .block__wp {
  margin-bottom: 53px; }

.block:nth-child(3) .gallery__slider {
  margin-bottom: 74px; }

.gallery__slider {
  padding-bottom: 50px;
  margin-bottom: 120px; }

.gallery .swiper-pagination.block__pagination {
  bottom: 0;
  margin-top: 0; }
  .gallery .swiper-pagination.block__pagination .swiper-pagination-bullet {
    background: #CDEDFF; }
    .gallery .swiper-pagination.block__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #DA49D9; }

.gallery__img {
  margin-bottom: 26px;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(111, 144, 209, 0.1)) drop-shadow(0px 8px 12px rgba(111, 144, 209, 0.2));
  filter: drop-shadow(0px 4px 4px rgba(111, 144, 209, 0.1)) drop-shadow(0px 8px 12px rgba(111, 144, 209, 0.2)); }
  .gallery__img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; }

.gallery__text {
  text-align: center; }

.form {
  margin-bottom: 260px; }
  .form__block {
    padding: 46px 147px 80px 118px;
    background: #0153FF;
    border-radius: 80px;
    max-width: 1280px; }
    .form__block h2 {
      font-weight: 600;
      font-size: 56px;
      line-height: 90px;
      letter-spacing: -0.04em;
      color: #FFFEFC; }
    .form__block p {
      color: #FFFEFC;
      margin-bottom: 23px;
      max-width: 640px; }
  .form__bg {
    position: absolute;
    right: -290px;
    top: -20px;
    width: 380px;
    height: 408px; }
    .form__bg img {
      width: 100%;
      height: 100%; }
  .form__wp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -40px; }
  .form__item {
    margin-right: 40px;
    width: calc(100% - 565px); }
    .form__item:first-child {
      width: 285px; }
    .form__item:last-child {
      width: 200px; }
    .form__item input {
      background: rgba(255, 254, 252, 0.3);
      border-radius: 20px;
      height: 84px;
      padding: 20px 20px 20px 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #FFFEFC;
      font-family: 'Proxima Nova Lt'; }
      .form__item input::-webkit-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt'; }
      .form__item input::-moz-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt'; }
      .form__item input:-ms-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt'; }
      .form__item input::-ms-input-placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt'; }
      .form__item input::placeholder {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFEFC;
        font-family: 'Proxima Nova Lt'; }
  .form__btn {
    background: #FFFEFC;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0153FF;
    font-family: 'Proxima Nova Lt';
    height: 84px;
    width: 100%; }
    .form__btn:hover {
      background: rgba(255, 254, 252, 0.3);
      color: #fffefc; }

@media (max-width: 1639px) {
  .steps__item:nth-child(7) {
    margin: 0 0 48px auto;
    padding-left: 888px;
    margin-top: -18px; } }

@media (max-width: 1539px) {
  .steps__item:nth-child(4) {
    margin-bottom: 15px; }
  .steps__item:nth-child(5) {
    padding-left: 668px; }
  .steps__item:nth-child(6) {
    padding-left: 640px;
    margin: 0 auto 50px auto; }
  .steps__item:nth-child(7) {
    padding-left: 760px; }
  .steps__item:nth-child(8) {
    margin-top: -110px; } }

@media (max-width: 1439px) {
  .steps__item:nth-child(4) {
    padding-left: 796px;
    margin-top: 60px;
    margin-bottom: 60px; }
  .steps__item:nth-child(6) {
    padding-left: 618px;
    margin: 0 auto 80px auto; }
  .steps__item:nth-child(7) {
    padding-left: 595px;
    padding-left: 620px; }
  .steps__item:nth-child(8) {
    margin-top: -13px; }
  .steps__item:nth-child(5) {
    padding-left: 740px; }
  .steps__item {
    padding-bottom: 40px; }
  .homescreen__title {
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 15px; }
  .homescreen__note {
    font-size: 30px;
    line-height: 32px;
    max-width: 660px; }
  .homescreen {
    padding: 140px 0 82px 0;
    margin-bottom: 160px; }
  .homescreen .line_1 {
    top: 200px; }
  .homescreen .line_2 {
    top: 42%; }
  .about .circle_1 {
    left: 0; }
  .about .circle_3 {
    right: 0; }
  .about {
    padding: 0 0 300px 0; }
  .about .line {
    z-index: 0; }
  .about .line_1 {
    bottom: -360px; }
  .about .line_2 {
    bottom: -413px; }
  .help h2 {
    margin-bottom: 100px; }
  .help__wp {
    margin-bottom: -20px;
    margin-right: -20px; }
  .help__icon {
    border-radius: 20px;
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    padding: 20px; }
  .help__item {
    width: calc(25% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 20px 20px 28px 20px; }
  .block__title {
    font-size: 30px;
    line-height: 30px; }
  .block__img {
    width: 40%;
    margin-right: 5%; }
  .block__text {
    width: calc(60% - 5%); }
  .block:nth-child(2) .block__img {
    margin-left: 5%; }
  .form__block h2 {
    font-size: 50px;
    line-height: 80px; }
  .form__block {
    padding: 46px 107px 60px 98px;
    background: #0153ff;
    border-radius: 60px;
    max-width: 1280px; }
  .form__item input {
    padding: 20px; } }

@media (max-width: 1339px) {
  .steps__item:nth-child(8) {
    margin-top: -46px; } }

@media (max-width: 1239px) {
  .form {
    margin-bottom: 200px; }
  .form__block {
    padding: 36px 57px 40px 48px; }
  .form__bg {
    display: none; }
  .form__item input {
    height: 64px; }
  .form__btn {
    height: 64px; }
  .form__block h2 {
    font-size: 40px;
    line-height: 60px; }
  .block__title {
    font-size: 24px;
    line-height: 24px; }
  .help__item {
    width: calc(50% - 20px); }
  .help h2 {
    margin-bottom: 60px; }
  .steps {
    margin-bottom: 150px; }
  .steps__line {
    display: none; }
  .steps__wp:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 2px;
    height: 50%;
    background: #DA49D9;
    top: 0;
    z-index: -1; }
  .steps__wp:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 2px;
    height: 50%;
    background: #CDEDFF;
    z-index: -1; }
  .steps__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .steps__text {
    margin-right: 393px; }
  .steps__item:first-child {
    padding-left: 0; }
  .steps__item:nth-child(2) {
    padding-left: 0;
    margin-top: 0; }
  .steps__item:nth-child(3) {
    padding-left: 0;
    margin-top: 0; }
  .steps__item:nth-child(4) {
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0; }
  .steps__item:nth-child(5) {
    margin-bottom: 0;
    padding-left: 0; }
  .steps__item:nth-child(6) {
    padding-left: 0;
    margin-bottom: 0;
    padding-bottom: 50px; }
  .steps__item:nth-child(7) {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0; }
  .steps__item:nth-child(8) {
    padding-left: 0;
    margin-top: 0;
    margin-right: 0; }
  .steps__item:nth-last-child(-n + 4) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    padding-right: 0; }
    .steps__item:nth-last-child(-n + 4) .steps__text {
      margin-left: 393px;
      margin-right: 0; }
    .steps__item:nth-last-child(-n + 4) .steps__icon {
      right: auto;
      left: -70px; }
  .steps__text {
    max-width: 300px; }
  .steps__item:nth-child(3) .steps__icon {
    left: auto; }
  .steps__item:nth-child(4) .steps__icon {
    left: auto;
    right: -80px; }
  .steps__item {
    padding-left: 200px; }
  .steps__item:nth-child(6) .steps__text {
    max-width: 300px; }
  .steps__item:nth-child(7) .steps__icon {
    bottom: -10px; }
  .steps__item:nth-child(3) .steps__icon {
    bottom: -10px; }
  .steps__item:nth-child(2) .steps__icon {
    bottom: -10px; }
  .steps__item:nth-child(8) .steps__icon {
    bottom: -10px; }
  .steps__item:nth-child(4) .steps__icon {
    bottom: -10px; }
  .steps__text {
    width: 100%; }
  .steps__icon {
    right: -70px;
    left: auto;
    bottom: -10px; }
  .video__title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 30px; }
  .about {
    padding: 0 0 200px 0; }
  .about p {
    font-size: 20px;
    line-height: 40px; }
  .homescreen__title {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px; }
  .homescreen__note {
    font-size: 25px;
    line-height: 29px;
    max-width: 560px; }
  .homescreen .line_1 {
    top: 125px; }
  .homescreen .line_2 {
    top: 33%; }
  .homescreen {
    padding: 115px 0 40px 0;
    margin-bottom: 110px; }
  .homescreen__img_text {
    right: 0px;
    bottom: 53px;
    padding: 5px 15px;
    font-weight: 600;
    line-height: 24px;
    font-size: 13px; }
  .homescreen__text {
    margin-top: 0; }
  .homescreen .circle_3 {
    right: 0; }
  .homescreen .circle_2 {
    right: 0; }
  .numbers__block {
    font-size: 50px;
    line-height: 50px; }
  .numbers__info p {
    font-size: 13px;
    line-height: 20px; }
  .title p {
    font-size: 30px;
    line-height: 40px;
    max-width: 700px; }
  .block__heading {
    margin-bottom: 60px; } }

@media (max-width: 1140px) {
  .form {
    margin-bottom: 100px; }
  .help h2 {
    margin-bottom: 40px; }
  .title p {
    font-size: 20px;
    line-height: 30px;
    max-width: 600px; }
  .steps {
    margin-bottom: 100px; }
  .about {
    padding: 0 0 100px 0; }
  .line {
    display: none; }
  .numbers__block {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px; }
  .numbers__info p {
    font-size: 11px;
    line-height: 16px; }
  .numbers__block {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 5px; }
  .numbers__wp {
    margin-bottom: 20px; }
  .block__wp {
    margin-bottom: 50px; }
  .gallery__slider {
    padding-bottom: 50px;
    margin-bottom: 60px; }
  .block__more a {
    padding: 20px 35px; }
  .block:nth-child(3) .gallery__slider {
    margin-bottom: 60px; }
  .block:nth-child(2) .gallery__slider {
    margin-bottom: 60px; } }

@media (max-width: 1023px) {
  .form__block h2 {
    font-size: 30px;
    line-height: 50px; }
  .form__block {
    border-radius: 40px; }
  .form__wp {
    margin-right: -20px; }
  .form__item {
    width: calc(100% - 430px); }
  .form__item:first-child {
    width: 220px; }
  .form__item:last-child {
    width: 150px; }
  .form__item {
    margin-right: 20px; }
  .form__block p {
    margin-bottom: 20px; }
  .form {
    margin-bottom: 60px; }
  .block:nth-child(3) .gallery__slider {
    margin-bottom: 30px; }
  .block:nth-child(2) .gallery__slider {
    margin-bottom: 30px; }
  .gallery__slider {
    margin-bottom: 30px; }
  .block__heading {
    margin-bottom: 30px; }
  .block__wp {
    display: block; }
  .block__text ul li {
    min-height: auto; }
  .block__text ul:before {
    bottom: 130px; }
  .block__img {
    display: none; }
  .block__text {
    width: 100%; }
  .block__wp {
    margin-bottom: 30px; }
  .block__title {
    font-size: 20px;
    line-height: 20px; }
  .title p {
    font-size: 18px;
    line-height: 28px;
    max-width: 550px; }
  .steps {
    margin-bottom: 60px; }
  .video__bg {
    left: 12%;
    top: 41px;
    width: 250px;
    height: 250px; }
  .arrowAnim_1 {
    left: -200px; }
  .arrowAnim_2 {
    right: -200px; }
  .video__wp {
    max-width: 600px;
    height: 350px; }
  .video__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 15px; }
  .video p {
    margin: 0 auto 20px auto; }
  .numbers__wp {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 5px; }
  .numbers__block {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .numbers__info {
    text-align: center;
    margin: 0 auto; }
  .numbers__item {
    text-align: center;
    margin-bottom: 15px;
    width: calc(33.3% - 10px);
    margin-right: 10px; }
  .numbers__wp {
    margin-right: -10px; }
  .homescreen__title {
    font-size: 40px;
    line-height: 40px; }
  .homescreen__note {
    font-size: 22px;
    line-height: 24px;
    max-width: 490px; }
  .homescreen__img_text {
    line-height: 18px;
    font-size: 12px;
    padding: 5px 8px; }
  .homescreen {
    padding: 115px 0 40px 0;
    margin-bottom: 67px; }
  .about {
    padding: 0 0 60px 0; }
  .about p {
    font-size: 18px;
    line-height: 35px;
    max-width: 80%;
    margin: 0 auto; }
  .block__heading h2 {
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .form__block h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px; }
  .form__block {
    padding: 30px 20px;
    border-radius: 20px; }
  .form__wp {
    display: block;
    margin-right: 0; }
  .form__item:first-child {
    width: 100%; }
  .form__item:last-child {
    width: 100%;
    margin-bottom: 0; }
  .form__item input {
    padding: 10px;
    height: 44px;
    font-size: 14px;
    border-radius: 10px; }
    .form__item input::-webkit-input-placeholder {
      font-size: 14px; }
    .form__item input::-moz-placeholder {
      font-size: 14px; }
    .form__item input:-ms-input-placeholder {
      font-size: 14px; }
    .form__item input::-ms-input-placeholder {
      font-size: 14px; }
    .form__item input::placeholder {
      font-size: 14px; }
  .form__btn {
    height: 44px;
    font-size: 14px;
    border-radius: 10px; }
  .form__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px; }
  .help__item {
    padding: 15px 10px;
    border-radius: 10px; }
  .help__icon {
    border-radius: 10px;
    margin-bottom: 15px;
    width: 80px;
    height: 80px;
    padding: 10px; }
  .title p {
    font-size: 16px;
    line-height: 25px;
    max-width: 550px; }
  .steps__wp:before {
    display: none; }
  .steps__wp:after {
    display: none; }
  .steps__text {
    margin-right: 0; }
  .steps__item {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .steps__item:nth-last-child(-n+4) {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .steps__item:nth-last-child(-n+4) .steps__text {
    margin-left: 0; }
  .video__play {
    width: 60px;
    height: 60px; }
  .video__bg {
    left: 50%;
    -webkit-transform: translateX(-90%);
    -ms-transform: translateX(-90%);
    transform: translateX(-90%);
    top: 26px;
    width: 225px;
    height: 225px; }
  .video__wp {
    max-width: 90%;
    height: 300px; }
  .about p {
    font-size: 16px;
    line-height: 30px;
    max-width: 90%;
    margin: 0 auto; }
  .homescreen__title {
    font-size: 30px;
    line-height: 30px; }
  .homescreen__note {
    font-size: 18px;
    line-height: 21px; }
  .homescreen__text {
    margin-right: 20px; }
  .homescreen__img {
    width: calc(47% - 20px); }
  .homescreen__img_text {
    line-height: 18px;
    font-size: 10px;
    border-radius: 5px;
    padding: 5px 8px; } }

@media (max-width: 567px) {
  .video__bg {
    left: 50%;
    -webkit-transform: translateX(-90%);
    -ms-transform: translateX(-90%);
    transform: translateX(-90%);
    top: 54px;
    width: 145px;
    height: 128px; }
  .gallery__img {
    margin-bottom: 20px; }
  .help__item {
    width: 100%; }
    .help__item:last-child {
      margin-bottom: 0; }
  .help__wp {
    display: block;
    margin-right: 0;
    margin-bottom: 0; }
  .help__icon {
    margin: 0 0 15px 0; }
  .help__text {
    text-align: left; }
  .steps__icon {
    position: relative;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    margin: 0 auto; }
  .steps__text p {
    text-align: center;
    margin-bottom: 15px; }
  .steps__item {
    padding-bottom: 0 !important;
    margin-bottom: 20px !important; }
  .steps__item:nth-last-child(-n+4) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .steps__item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .video__wp {
    height: 250px; }
  .numbers__item {
    width: calc(50% - 10px); }
  .homescreen {
    margin-bottom: 60px; }
  .about p {
    max-width: 100%; }
  .about .circle_1 {
    width: 30px;
    height: 30px; }
  .homescreen .container {
    display: block; }
  .homescreen__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    text-align: center; }
  .homescreen__img {
    text-align: center; }
  .homescreen .circle_1 {
    top: -38px; }
  .homescreen__img {
    width: 100%;
    height: 300px; }
    .homescreen__img img {
      height: auto;
      max-height: 100%;
      width: auto; }
  .homescreen__img_text {
    right: auto;
    left: 50%;
    white-space: nowrap;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); } }

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/
.section {
  margin-bottom: 200px; }

.circle {
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  -webkit-animation: scale infinite 5s;
  animation: scale infinite 5s; }

.line {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: -1; }
  .line:after, .line:before {
    top: -30px;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    width: 60px;
    z-index: 2;
    background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), color-stop(0.01%, #FFFFFF), to(rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(right, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%); }
  .line:after {
    right: 0; }
  .line:before {
    left: 0;
    background: -o-linear-gradient(2deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(88deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%); }

@keyframes scale {
  0% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5); }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5); } }

@media (max-width: 1139px) {
  .section {
    margin-bottom: 100px; } }

@media (max-width: 1023px) {
  .section {
    margin-bottom: 60px; }
  .circle {
    display: none; } }
